import loginImage from 'assets/loginModal.jpeg';
import {Trans, useTranslation} from 'next-i18next';
import Image from 'next/image';
import React from 'react';

import {Box, Grid, Typography} from 'components/basic-components';

import styles from './LoginModal.module.scss';

const LoginModalContent = ({
  title,
  children,
  hideRegisterText,
}: {
  title: string;
  children: React.ReactNode;
  hideRegisterText: Boolean;
}) => {
  const {t} = useTranslation('loginModal');

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: {xxs: 'column-reverse', md: 'row'},
      }}
    >
      <Grid xxs={12} md={6} sx={{display: 'flex', flexDirection: 'column', height: 'fit-content'}}>
        <Box className={styles.formWrapper}>
          <Box className={styles.innerFormWrapper}>
            <Typography variant="h2" className={styles.headline}>
              {title}
            </Typography>
            {children}
          </Box>
        </Box>
        {hideRegisterText ? null : (
          <Box className={styles.registrationWrapper}>
            <Box sx={{width: {xxs: '100%', xxl: '430px'}}}>
              <Typography variant="footerCards" sx={{lineHeight: '30px'}}>
                {t('noAccess')}
              </Typography>
              <Typography sx={{lineHeight: '24px', marginTop: '10px'}}>
                <Trans t={t} i18nKey="registerInfo" components={[<strong key="strong"></strong>]} />
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
      <Grid
        xxs={12}
        md={6}
        sx={{
          position: 'relative',
        }}
      >
        <Image className={styles.image} width={1000} height={1000} src={loginImage} alt="" />
        <Typography className={styles.mobileTitle} variant="h2">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginModalContent;
