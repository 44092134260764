import {FormProvider, useForm} from 'hooks/custom-react-hook-form';
import {useSession} from 'next-auth/react';
import {useTranslation} from 'next-i18next';
import React, {useEffect, useMemo} from 'react';

import {yupResolver} from '@hookform/resolvers/yup';

import {usePostApiV1PostCustomerPasswordReset} from 'lib/api/backend';

import {palette} from 'styles/mui/scss';

import {Box, Button, Typography} from 'components/basic-components';
import {FormInputText} from 'components/basic-components/Form/FormInputText';

import styles from './PasswordForgotForm.module.scss';
import yupSchema from './schema';

const PasswordForgotForm = ({
  onGoToLoginClick,
  handleSuccessPWEmail,
}: {
  onGoToLoginClick: () => void;
  handleSuccessPWEmail: () => void;
}) => {
  const {t} = useTranslation(['myStarcar', 'forms', 'password']);

  const schema = yupSchema(t);

  const useFormMethods = useForm({
    name: 'passwort-vergessen',
    defaultValues: {email: ''},
    resolver: yupResolver(schema),
  });

  const {handleSubmit, watch, reset} = useFormMethods;

  const email = watch('email');

  const session = useSession();

  const headers = useMemo(
    () =>
      session?.data?.user?.accessToken
        ? {
            'X-Auth': `Bearer ${session?.data?.user?.accessToken}`,
          }
        : {},
    [session?.data?.user?.accessToken],
  );
  const {mutate, isSuccess} = usePostApiV1PostCustomerPasswordReset({
    request: {headers},
  });

  const submitHandler = () => {
    mutate({data: {email}});
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      handleSuccessPWEmail();
    }
  }, [isSuccess]);

  return (
    <Box>
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          {isSuccess ? (
            <Typography className={styles.successMessage}>
              {t('pwResetMailSuccess', {
                ns: 'password',
              })}
            </Typography>
          ) : (
            <>
              <FormInputText
                label={t('email', {ns: 'common'})}
                placeholder={t('email', {ns: 'common'})}
                variant="filled"
                sx={{
                  width: '100%',
                  height: '100%',
                  '& .MuiFilledInput-root': {outline: `1px solid ${palette.colorMidGrey}`},
                }}
                name="email"
                key="email"
                autoComplete="email"
              />
              <Box className={styles.buttonWrapper}>
                <Button className={styles.button} type="submit">
                  {t('sent', {ns: 'common'})}
                </Button>
              </Box>
              <Typography
                component="span"
                role="button"
                tabIndex={0}
                onClick={onGoToLoginClick}
                className={styles.goBack}
              >
                {t('goBack', {ns: 'common'})}
              </Typography>
            </>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};

export default PasswordForgotForm;
