import {StarcarDateRangePickerStateContext} from 'hooks/BPR/useStarcarDateRangePickerState';
import {FormProvider} from 'hooks/custom-react-hook-form';
import React from 'react';

import {palette} from 'styles/mui/scss';

import {Box, DateRangePicker} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';

import {useBestPriceRechner} from '../../../hooks/BPR/useBestPriceRechner';
import BestPriceRechnerMobile from './BPRMobile/BestPriceRechnerMobile';
import FormInputDistance from './FormFields/FormInputDistance';
import SelectCategory from './FormFields/SelectCategory';
import SubmitButton from './FormFields/SubmitButton';
import StationSelectionFlyout from './StationSelection/StationSelection';

const BestPriceRechner = props => {
  const {
    formMethods,
    onSubmit,
    emergencyOnPointerDownHandler,
    control,
    errors,
    fieldValues,
    stationsById,
    isDesktop,
    bprRef,
    starcarDateRangePickerState,
    stations,
    presetStation,
  } = useBestPriceRechner(props);

  return (
    <FormProvider {...formMethods}>
      <StarcarDateRangePickerStateContext.Provider value={starcarDateRangePickerState}>
        <form
          onPointerDown={emergencyOnPointerDownHandler}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          id="form-best-price"
          ref={bprRef}
        >
          {isDesktop ? (
            <ContentWrapper sx={{backgroundColor: palette.colorBlackAlt}}>
              <Box sx={{margin: '0 3%', padding: '10px 0', color: 'white'}}>
                <SelectCategory />
                <Box sx={{display: 'flex', columnGap: {lg: '5px', xxl: '20px'}, marginTop: '5px'}}>
                  <Box
                    data-form-id="InputStation"
                    sx={{width: {lg: '30%', xl: '33%', xxl: '40%'}, height: 'fit-content'}}
                  >
                    <StationSelectionFlyout
                      control={control}
                      disabled={!!presetStation}
                      errors={errors}
                      setStationTextField={(value: string) => {
                        formMethods.setValue('stationTextField', value, {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        starcarDateRangePickerState.resetDatepicker();
                        starcarDateRangePickerState.resetFormValues();
                        starcarDateRangePickerState.setCalendarOpen(true);
                        formMethods.clearErrors('station');
                      }}
                      setStation={station => {
                        formMethods.setValue('station', station?.id || '', {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        console.log('setStation', station);
                        formMethods.clearErrors('station');
                      }}
                      stations={stations}
                      selectedStation={stationsById?.[fieldValues.station] || null}
                      value={fieldValues.stationTextField}
                      isWidget={false}
                      showPopularStations={false}
                    />
                  </Box>
                  <Box
                    data-form-id="InputDate"
                    sx={{width: {lg: '55%', xl: '47%', xxl: '45%'}, height: 'fit-content'}}
                  >
                    <DateRangePicker isWidget={false} />
                  </Box>
                  <Box
                    data-form-id="InputDistance"
                    sx={{width: {lg: '15%'}, height: 'fit-content'}}
                  >
                    <FormInputDistance
                      control={control}
                      errors={errors}
                      isWidget={false}
                      disabled={!(fieldValues?.stationTextField && fieldValues?.station || fieldValues?.distance)}
                    />
                  </Box>
                  <SubmitButton
                    opacity={
                      (fieldValues.arrival &&
                        fieldValues.departure &&
                        fieldValues.station &&
                        !Object.keys(errors)?.length &&
                        1) ||
                      0.7
                    }
                  />
                </Box>
              </Box>
            </ContentWrapper>
          ) : (
            <BestPriceRechnerMobile
              presetStation={presetStation}
              errors={errors}
              stations={stations}
              stationsById={stationsById}
            />
          )}
        </form>
      </StarcarDateRangePickerStateContext.Provider>
    </FormProvider>
  );
};

export default React.memo(withErrorBoundary(BestPriceRechner, 'BestPriceRechner'));
