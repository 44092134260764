import {useState} from 'react';

import {Button} from 'components/basic-components';

import styles from './GridOverlay.module.scss';

const GridOverlay = () => {
  const [active, setActive] = useState(false);

  const toggleGrid = () => {
    setActive(!active);
  };
  return (
    <>
      {active ? (
        <div className={styles.gridOverlay}>
          <div className={styles.container}>
            <div className={styles.row}>
              {/* Generate columns */}
              {[...Array(12)].map((_, index) => (
                <div key={index} className={styles.column}></div>
              ))}
            </div>
          </div>
        </div>
      ) : null}

      <Button
        variant="secondary"
        onClick={toggleGrid}
        sx={{position: 'fixed', bottom: 75, left: 10, zIndex: '10000'}}
      >
        {active ? 'Disable Grid' : 'Enable Grid'}
      </Button>
    </>
  );
};

export default GridOverlay;
