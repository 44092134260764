import {useStarcarDateRangePickerStateContext} from 'hooks/BPR/useStarcarDateRangePickerState';
import {useFormContext} from 'hooks/custom-react-hook-form';
import {useTranslation} from 'next-i18next';
import React, {useRef, useState} from 'react';

import {palette} from 'styles/mui/scss';

import {Box, DateRangePicker, Grid, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';

import FormInputDistance from '../FormFields/FormInputDistance';
import SelectCategory from '../FormFields/SelectCategory';
import SubmitButton from '../FormFields/SubmitButton';
import StationSelectionFlyout from '../StationSelection/StationSelection';
import styles from './BestPriceRechnerMobile.module.scss';

const BestPriceRechnerMobile = ({presetStation, errors, stations, stationsById}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const bprRef = useRef(null);

  const {clearErrors, control, watch, setValue} = useFormContext();

  const fieldValues = watch();

  const handleCloseMenu = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setIsExpanded(!isExpanded);
  };
  const {t} = useTranslation('bpr');
  const { setCalendarOpen, resetDatepicker, resetFormValues} =
    useStarcarDateRangePickerStateContext();
  return (
    <ContentWrapper sx={{backgroundColor: palette.colorBlackAlt}}>
      <Box sx={{margin: '0 3%', padding: '10px 0'}}>
        <Box
          ref={bprRef}
          sx={{
            width: '100%',
            backgroundColor: palette.colorPrimary,
            borderRadius: '2px',
            padding:
              !fieldValues.station &&
              !fieldValues.departure &&
              !fieldValues.arrival &&
              !fieldValues.distance
                ? '18px 10px'
                : '8px 10px 10px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <CustomIcon
            name="magnifyingglass"
            style={{height: '24px', width: '24px', marginRight: '10px'}}
          />
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {!fieldValues.station &&
            !fieldValues.departure &&
            !fieldValues.arrival &&
            !fieldValues.distance ? (
              <Typography sx={{fontWeight: 700, color: palette.colorGrey80}}>
                {t('carSearch')}
              </Typography>
            ) : (
              <>
                <Typography sx={{fontWeight: 700, lineHeight: '24px', color: palette.colorGrey80}}>
                  {fieldValues?.station ? fieldValues?.stationTextField : null}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    '& p': {fontSize: '12px', lineHeight: '18px', color: palette.colorGrey80},
                  }}
                >
                  <Typography>
                    <strong>
                      {fieldValues?.departure
                        ? `${fieldValues?.departure?.day}.${fieldValues?.departure?.month}.${fieldValues?.departure?.year}`
                        : null}
                    </strong>{' '}
                    {fieldValues?.departureTime}
                  </Typography>
                  <Typography sx={{margin: '0 5px'}}>-</Typography>
                  <Typography>
                    <strong>
                      {fieldValues?.arrival
                        ? `${fieldValues?.arrival?.day}.${fieldValues?.arrival?.month}.${fieldValues?.arrival?.year}`
                        : null}
                    </strong>{' '}
                    {fieldValues?.arrivalTime}
                  </Typography>
                  <Box sx={{display: 'flex'}}>
                    <Box
                      sx={{width: '1px', margin: '0 5px', backgroundColor: palette.colorGrey10}}
                    />
                    <Typography sx={{fontWeight: 700}}>{fieldValues?.distance}km</Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={`${styles.openedBPR} ${isExpanded ? styles.expanded : null}`}>
        <Box sx={{padding: '0 0 10px 0'}}>
          <SelectCategory />
          <Grid container rowSpacing={2.5} sx={{paddingTop: '10px'}}>
            <Grid xxs={12}>
              <StationSelectionFlyout
                control={control}
                disabled={!!presetStation}
                errors={errors}
                setStationTextField={(value: string) => {
                  setValue('stationTextField', value, {shouldDirty: true, shouldTouch: true});
                  resetDatepicker();
                  resetFormValues();
                  setCalendarOpen(true);
                  clearErrors('station');
                }}
                setStation={station => {
                  setValue('station', station?.id || '', {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  console.log('setStation', station);
                  clearErrors('station');
                }}
                stations={stations}
                selectedStation={stationsById?.[fieldValues.station] || null}
                value={fieldValues.stationTextField}
                isWidget={false}
                showPopularStations={false}
              />
            </Grid>
            <Grid xxs={12}>
              <DateRangePicker isWidget={false} />
            </Grid>
            <Grid xxs={12}>
              <FormInputDistance
                control={control}
                errors={errors}
                isWidget={false}
                disabled={!(fieldValues?.stationTextField && fieldValues?.station || fieldValues?.distance)}
              />
            </Grid>
            <Grid xxs={12} sx={{marginTop: '5px'}}>
              <SubmitButton
                opacity={
                  (fieldValues.arrival &&
                    fieldValues.departure &&
                    fieldValues.station &&
                    !Object.keys(errors)?.length &&
                    1) ||
                  0.7
                }
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: palette.colorPrimary,
              cursor: 'pointer',
              width: 'fit-content',
              margin: '20px auto 10px',
            }}
            className="close-bpr-modal-button"
            onClick={handleCloseMenu}
          >
            <CustomIcon name="chevronUp" style={{height: '24px', width: '24px'}} />
            <Typography
              className="close-bpr-modal-button"
              sx={{lineHeight: '24px', fontWeight: 700, marginLeft: '5px'}}
            >
              {t('close')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ContentWrapper>
  );
};

export default BestPriceRechnerMobile;
