import {NavEntry} from 'context/navPropsContext';
import {useTranslation} from 'next-i18next';
import React, {useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import useMediaQuery from '@mui/material/useMediaQuery';

import {Box, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import {NextLink} from 'components/basic-components/NextLink/Link';

import styles from './Menu.module.scss';

const DropDownMenu = ({
  headerLinks,
  headerMenuLinks,
}: {
  headerLinks: NavEntry[];
  headerMenuLinks: NavEntry[];
}) => {
  const {t} = useTranslation('common');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const max629 = useMediaQuery('(max-width:629px)');

  const closeMenu = () => setDropdownOpen(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  return (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <Box style={{position: 'relative', display: 'flex'}}>
        <button aria-label="Menü" className={styles.dropDownMenuButton} onClick={toggleDropdown}>
          <CustomIcon name="menu" className={styles.icon} />
          <Typography className={styles.dropDownMenuButtonText} variant="menuLabel">
            {t('menu')}
          </Typography>
        </button>
        {isDropdownOpen && (
          <div className={styles.dropDownMenuWrapper}>
            <ul className={styles.ul}>
              {max629
                ? headerMenuLinks?.map((link: NavEntry) => {
                    return (
                      <li key={link?.id} className={styles.menuItem}>
                        <Typography variant="buttonCards" sx={{cursor: 'pointer'}}>
                          <NextLink onClick={closeMenu} className={styles.link} href={`${link.href}`}>
                            {link.title}
                          </NextLink>
                        </Typography>
                      </li>
                    );
                  })
                : null}
              {headerLinks?.map((link: NavEntry) => {
                return (
                  <li key={link?.id} className={styles.menuItem}>
                    <Typography variant="buttonCards" sx={{cursor: 'pointer'}}>
                      <NextLink onClick={closeMenu} className={styles.link} href={`${link.href}`}>
                        {link.title}
                      </NextLink>
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </Box>
    </OutsideClickHandler>
  );
};

export default DropDownMenu;
