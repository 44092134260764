import * as yup from 'yup';

const yupSchema = t => {
  const schema = yup.object({
    email: yup
      .string()
      .trim()
      .email(t('invalidMail', {ns: 'forms'}))
      .required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
  });

  return schema;
};

export default yupSchema;
