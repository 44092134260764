import {useTranslation} from 'next-i18next';
import React, {useState} from 'react';

import {Box} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import Modal from 'components/basic-components/Modal';
// import {withErrorBoundary} from 'components/error/ErrorBoundary';
import LoginForm from 'components/form-components/LoginForm';
import PasswordForgotForm from 'components/form-components/PasswordForgotForm';

import styles from './LoginModal.module.scss';
import LoginModalContent from './LoginModalContent';

const LoginModal = ({modalOpen, setModalOpen}) => {
  const {t} = useTranslation('loginModal');

  const [isForgotPasswordClicked, setIsForgotPasswordClicked] = useState(false);
  const [isForgotPasswordEmailSuccess, setIsForgotPasswordEmailSuccess] = useState(false);

  const handleForgotPasswordClick = () => {
    setIsForgotPasswordClicked(true);
  };

  const handleBackToLoginClick = () => {
    setIsForgotPasswordClicked(false);
  };

  const handleSuccessPWEmail = () => {
    if (isForgotPasswordClicked) {
      setIsForgotPasswordEmailSuccess(true);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(!modalOpen),
          setIsForgotPasswordClicked(false),
          setIsForgotPasswordEmailSuccess(false);
      }}
    >
      <Box className={styles.modalContainer}>
        <Box sx={{position: 'relative'}}>
          <LoginModalContent
            hideRegisterText={isForgotPasswordClicked}
            title={
              isForgotPasswordClicked && isForgotPasswordEmailSuccess
                ? t('confirmChange')
                : isForgotPasswordClicked
                ? t('resetPassword')
                : t('login')
            }
          >
            {isForgotPasswordClicked ? (
              <PasswordForgotForm
                onGoToLoginClick={handleBackToLoginClick}
                handleSuccessPWEmail={handleSuccessPWEmail}
              />
            ) : (
              <LoginForm
                onLoginSuccess={() => setModalOpen(false)}
                onForgotPasswordClick={handleForgotPasswordClick}
              />
            )}
          </LoginModalContent>
          <button
            aria-label={t('closeModal')}
            className={styles.closingIconWrapper}
            onClick={() => {
              setModalOpen(!modalOpen),
                setIsForgotPasswordClicked(false),
                setIsForgotPasswordEmailSuccess(false);
            }}
          >
            <CustomIcon name="close" className={styles.icon} />
          </button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LoginModal;
