import {NavPropsContext} from 'context/navPropsContext';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import React, {FC, useContext} from 'react';

import {palette} from 'styles/mui/scss';

import {Box, Typography} from 'components/basic-components';
import {NextLink} from 'components/basic-components/NextLink/Link';

import ContentWrapper from '../ContentWrapper/ContentWrapper';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import DropDownLogin from '../Menu/DropDownLogin';
import DropDownMenu from '../Menu/DropDownMenu';
import styles from './Navbar.module.scss';
import Logo from './assets/logo_starcar2x.png';
import {NavbarProps} from './types';

const Navbar: FC<NavbarProps> = ({translatedSlugs}: NavbarProps) => {
  const {t} = useTranslation('common');

  const {headerLinks, headerMenuLinks} = useContext(NavPropsContext);

  return (
    <ContentWrapper
      id={'headerWrapper'}
      sx={{backgroundColor: palette.colorDarkGrey, position: 'sticky', top: 0, zIndex: 1302}}
    >
      <Box className={styles.contentWrapper}>
        <Box sx={{marginRight: {sm: '40px', xl: '80px'}}}>
          <NextLink style={{display: 'flex'}} href="/">
            <Image src={Logo} alt={t('logo')} height={25} />
          </NextLink>
        </Box>
        <Box className={styles.linkWrapper}>
          <Box sx={{display: {xxs: 'none', sm: 'block'}}}>
            {headerMenuLinks?.map(link => {
              return (
                link.href && (
                  <Typography key={link.id} className={styles.linkText} variant="menuLabel">
                    <NextLink href={`${link.href}`}>{link.title}</NextLink>
                  </Typography>
                )
              );
            })}
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <LanguageSwitch translatedSlugs={translatedSlugs} />
            <DropDownLogin />
            <DropDownMenu headerLinks={headerLinks} headerMenuLinks={headerMenuLinks} />
          </Box>
        </Box>
      </Box>
    </ContentWrapper>
  );
};

export default Navbar;
