import {FooterMenuGroup} from 'context/navPropsContext';
import {useTranslation} from 'next-i18next';
import React, {useContext} from 'react';

import {Box, Typography} from 'components/basic-components';
import {NextLink} from 'components/basic-components/NextLink/Link';
import {ConsentContext} from 'components/section-components/ConsentManager';

import styles from './Footer.module.scss';

const FooterLists = ({listFooterMenuGroups}: {listFooterMenuGroups: FooterMenuGroup[]}) => {
  const {t} = useTranslation('common');
  const {openModal} = useContext(ConsentContext);

  return (
    <>
      {listFooterMenuGroups.map((group, index) => {
        return (
          <Box sx={{width: '32%'}} key={index}>
            <Box sx={{paddingBottom: '10px'}}>
              <Typography variant="buttonCards">{group?.title}</Typography>
            </Box>
            <Box className={styles.footerListLinkWrapper}>
              {group.menuLinks.map(link => {
                if (
                  link.title.toLowerCase() === 'datenschutz-einstellungen' ||
                  link.title.toLowerCase() === 'data protection settings'
                ) {
                  return (
                    <Typography
                      key="customDatenschutz"
                      className={styles.footerLinkStyles}
                      sx={{cursor: 'pointer'}}
                      onClick={e => {
                        e.preventDefault();
                        openModal();
                      }}
                    >
                      {t('dataPrivacySettings')}
                    </Typography>
                  );
                }
                return (
                  <Typography className={styles.footerLinkStyles} key={link.id}>
                    <NextLink href={link?.href} target={link.newWindow ? '_blank' : '_self'}>
                      {link.title}
                    </NextLink>
                  </Typography>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default FooterLists;
