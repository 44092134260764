import {useGetTranslatedCategory} from 'hooks/useGetTranslatedCategory';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';

import {palette} from 'styles/mui/scss';

import {Box, Typography} from 'components/basic-components';

import styles from './styles/formSelectCategory.module.scss';
import { CustomIcon } from 'components/basic-components/CustomIcon/CustomIcon';

const SelectCategory = () => {
  const {t} = useTranslation('bpr');

  return (
    <Box className={styles.wrapper}>
      <CategoryButton value={t('bestpriceCategoryCar')} label={t('bestpriceCategoryCarLabel')} />
      <CategoryButton
        value={t('bestpriceCategoryTruck')}
        label={t('bestpriceCategoryTruckLabel')}
      />
    </Box>
  );
};

export default SelectCategory;

function CategoryButton({value, label}) {
  const {setValue, watch} = useFormContext();
  const {t} = useTranslation('bpr');

  const {locale} = useRouter();
  const getTranslatedCategory = useGetTranslatedCategory();

  useEffect(() => {
    setValue('category', getTranslatedCategory('pkw'));
  }, [locale]);

  const currentValue = watch('category');
  return (
    <button
      onClick={e => {
        e.preventDefault();
        setValue('category', value);
      }}
      className={styles.button}
      style={{
        backgroundColor: value === currentValue ? 'rgba(254, 237, 1, 0.1)' : 'inherit',
        color: value === currentValue ? palette.colorPrimary : palette.colorMidGrey,
      }}
    >
      <CustomIcon className={styles.categoryIcon} name={t('bestpriceCategoryCar') === value ? 'car' : 'truck'}></CustomIcon>
      <Typography variant="buttonCards" className={styles.label}>
      {label}
      </Typography>
    </button>
  );
}
