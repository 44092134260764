import {FooterMenuGroup} from 'context/navPropsContext';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {palette} from 'styles/mui/scss';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from 'components/basic-components';
import {NextLink} from 'components/basic-components/NextLink/Link';

import styles from './Footer.module.scss';

function splitArrayIntoThreeEqualParts(array) {
  const totalLength = array.length;
  const partSize = Math.ceil(totalLength / 3);

  const result = [];

  for (let i = 0; i < totalLength; i += partSize) {
    result.push(array.slice(i, i + partSize));
  }

  return result;
}

const renderMenuLinks = links => {
  return links.map(link => (
    <Box
      key={link.id}
      sx={{
        paddingBottom: '5px',
        '&:last-of-type': {
          paddingBottom: 0,
        },
      }}
    >
      <Typography sx={{fontWeight: link.bold ? 700 : ''}} className={styles.footerLinkStyles}>
        <NextLink href={link.href} target={link.newWindow ? '_blank' : '_self'}>
          {link.title}
        </NextLink>
      </Typography>
    </Box>
  ));
};

const FooterStationsAccordion = ({
  accordionFooterMenuGroups,
}: {
  accordionFooterMenuGroups: FooterMenuGroup[];
}) => {
  return (
    <>
      {accordionFooterMenuGroups?.map((group, index) => {
        const splittedMenuLinks = splitArrayIntoThreeEqualParts(group?.menuLinks);

        return (
          <Box sx={{width: '100%'}} key={index}>
            <Accordion sx={{backgroundColor: palette.colorGrey03}} variant="base">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{width: '24px', height: '24px'}} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="footerCards">{group.title}</Typography>
              </AccordionSummary>
              <AccordionDetails className={styles.accordionDetails}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: '2%',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  }}
                >
                  {splittedMenuLinks.map((links, index) => (
                    <Box
                      sx={{
                        paddingLeft: index === 0 ? '15px' : null,
                        paddingRight: index === splittedMenuLinks.length - 1 ? '15px' : null,
                      }}
                      key={index}
                    >
                      {renderMenuLinks(links)}
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
    </>
  );
};

export default FooterStationsAccordion;
