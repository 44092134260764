import {FooterBox, FooterMenuGroup, NavPropsContext} from 'context/navPropsContext';
import {useContext} from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import {Box} from 'components/basic-components';

import ContentWrapper from '../ContentWrapper/ContentWrapper';
import styles from './Footer.module.scss';
import FooterStationsAccordion from './FooterAccordions';
import FooterBoxes from './FooterBoxes';
import FooterLists from './FooterLists';
import FooterMobile from './FooterMobile';

const Footer = () => {
  const mobileFooter = useMediaQuery('(max-width:629px)');
  const navProps = useContext(NavPropsContext);
  const footerMenuGroups: FooterMenuGroup[] = navProps?.footerMenuGroups || [];
  const footerBoxes: FooterBox[] = navProps?.footerBoxes || [];

  const accordionFooterMenuGroups = footerMenuGroups?.filter(
    group => group?.renderAs === 'accordion',
  );

  const listFooterMenuGroups = footerMenuGroups?.filter(group => group?.renderAs === 'list');

  return (
    <ContentWrapper id="footer" sx={{backgroundColor: theme => theme.palette.primary.main}}>
      <Box>
        {mobileFooter ? (
          <FooterMobile footerMenuGroups={footerMenuGroups} />
        ) : (
          <>
            <Box className={styles.footerCardsWrapper}>
              <FooterBoxes footerBoxes={footerBoxes} />
              <FooterStationsAccordion accordionFooterMenuGroups={accordionFooterMenuGroups} />
            </Box>
            <Box className={styles.footerListWrapper}>
              <FooterLists listFooterMenuGroups={listFooterMenuGroups} />
            </Box>
          </>
        )}
      </Box>
    </ContentWrapper>
  );
};

export default Footer;
