import React, {useEffect, useState} from 'react';

import {Box, Button, Grid, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './feedbackDialogue.module.scss';
import FeedbackDrawer from './FeedbackDrawer';
import {useTranslation} from "next-i18next";
import clsx from "clsx";

const FeedbackDialogue = () => {
  const {t} = useTranslation('feedback');
  const [open, setOpen] = useState(false);
  const [showHighlightText, setShowHighlightText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowHighlightText(true);
    }, 13000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowHighlightText(false);
    }, 19000);
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid container justifyContent={'end'} alignItems={"center"} className={clsx(styles.container, {[styles.showHighlightText]: showHighlightText})}  onClick={handleClick}>
        <Box onTouchEnd={(e) => {console.log('click');setShowHighlightText(false);e.preventDefault(); e.stopPropagation();}} className={clsx(styles.highlightTextWrapper, {[styles.showHighlightText]: showHighlightText})}>
        <Typography
          className={clsx(styles.highlightText, {[styles.showHighlightText]: showHighlightText})}
          variant='h2'>
          {t('highlightText')}
        </Typography>
            </Box>
        <Button className={styles.button}>
          <CustomIcon name="star" className={styles.icon}/>
        </Button>
      </Grid>
      <FeedbackDrawer open={open} onClose={() => setOpen(false)}/>
    </>
);

};

export default withErrorBoundary(FeedbackDialogue, 'FeedbackDialogue');
