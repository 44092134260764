import SpinnerContext from 'context/spinnerContext';
import {useForm} from 'hooks/custom-react-hook-form';
import {signIn} from 'next-auth/react';
import {useTranslation} from 'next-i18next';
import {useContext, useState} from 'react';
import * as yup from 'yup';

import {yupResolver} from '@hookform/resolvers/yup';

import {palette} from 'styles/mui/scss';

import {Box, Button, Grid, TextField, Typography} from 'components/basic-components';

import styles from './LoginForm.module.scss';

const LoginForm = ({
  onLoginSuccess = () => {},
  onForgotPasswordClick,
}: {
  onLoginSuccess: Function;
  onForgotPasswordClick: () => void;
}) => {
  const {t} = useTranslation(['loginModal', 'password', 'forms', 'common']);
  const schema = yup.object({
    email: yup
      .string()
      .trim()
      .email(t('invalidEmail'))
      .required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
    password: yup.string().required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
  });

  const {register, handleSubmit} = useForm({
    name: 'LoginForm',
    defaultValues: {email: '', password: ''},
    resolver: yupResolver(schema),
  });
  const {addRequestWithGeneratedId} = useContext(SpinnerContext);
  const [submitErrors, setSubmitErrors] = useState({email: [''], password: ['']});
  const errors = submitErrors;

  const submitLogin = async ({email, password}) => {
    const {resolve} = addRequestWithGeneratedId();
    try {
      setSubmitErrors({email: [''], password: ['']});
      await signIn('credentials', {username: email, password: password, redirect: false}).then(
        ({ok}) => {
          if (ok) {
            onLoginSuccess();
          } else {
            setSubmitErrors({
              email: [t('pwOrUsernameWrong', {ns: 'password'})],
              password: [t('pwOrUsernameWrong', {ns: 'password'})],
            });
          }
          resolve();
        },
      );
      return;
    } catch (err) {
      resolve();
      setSubmitErrors({
        email: [t('pwOrUsernameWrong', {ns: 'password'})],
        password: [t('pwOrUsernameWrong', {ns: 'password'})],
      });
      return;
    }
  };
  const submitForm = handleSubmit((data: {email: string; password: string}) => {
    submitLogin(data);
  });
  return (
    <>
      <form data-role="login-form" onSubmit={submitForm}>
        <Grid container columnSpacing={8} rowSpacing={5}>
          <Grid xxs={12}>
            <TextField
              className={styles.textField}
              sx={{'& .MuiFilledInput-root': {outline: `1px solid ${palette.colorMidGrey}`}}}
              variant="filled"
              placeholder={t('email', {ns: 'common'})}
              type="email"
              autoComplete="email"
              {...register('email')}
              InputProps={{
                ...register('email'),
              }}
              error={!!errors.email[0]}
              helperText={errors.email[0]}
              label={t('email', {ns: 'common'})}
            />
          </Grid>
          <Grid xxs={12}>
            <TextField
              className={styles.textField}
              sx={{'& .MuiFilledInput-root': {outline: `1px solid ${palette.colorMidGrey}`}}}
              variant="filled"
              placeholder={t('pw', {ns: 'password'})}
              type="password"
              autoComplete="password"
              InputProps={{
                ...register('password'),
              }}
              {...register('password')}
              error={!!errors.password[0]}
              helperText={errors.password[0]}
              label={t('pw', {ns: 'password'})}
            />
          </Grid>
        </Grid>
        <Box className={styles.buttonWrapper}>
          <Button className={styles.button} type="submit">
            {t('login')}
          </Button>
        </Box>
      </form>

      <Typography
        component="span"
        role="button"
        tabIndex={0}
        onClick={onForgotPasswordClick}
        className={styles.forgotPassword}
      >
        {t('forgotPw?', {ns: 'password'})}
      </Typography>
    </>
  );
};
export default LoginForm;
