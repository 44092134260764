import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Image, { StaticImageData } from 'next/image';
import { useTranslation, Trans } from 'next-i18next';

import qrImage from './assets/qr.png'; 
import badIcon from './assets/bad.png';
import goodIcon from './assets/good.png';
import styles from './feedbackDialogue.module.scss';

import midIcon from './assets/mid.png';
import { TFeedBackOptions } from './FeedbackDrawer';
import { ButtonWithConfig } from 'components/basic-components/Button/ButtonWithConfig';
type FeedBackImageMapping = {
    [key in TFeedBackOptions]: StaticImageData;
  };
type FeedBacktoString = {
[key in TFeedBackOptions]: string;
};

const feedBackImageMapping : FeedBackImageMapping = {
    'bad': badIcon,
    'mid': midIcon,
    'good': goodIcon
}
const feedBackAltTextMapping : FeedBacktoString = {
    'bad': "Bad Feedback image",
    'mid': "Neutral Feedback image",
    'good': "Good Feedback image"
}
const feedBackTranslationMapping : FeedBacktoString = {
    'bad': 'design_not_liked',
    'mid': 'design_neutral_liked',
    'good': 'design_liked'
}
const FeedbackThankYou = ({selectedFeedback}: {selectedFeedback: TFeedBackOptions}) => {
    const { t } = useTranslation('feedback');
    
  return (
    <Box
        sx={{
        width: { sm: '420px', xxs: '100vw' },
        maxWidth: '100vw',
        padding: '20px 3%',
        height: 'calc(100% + 220px)',
      }}
    >
      <Typography component="p" variant="h1" sx={{mb: "20px"}} >
        {t('thank_you')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Image
          src={feedBackImageMapping[selectedFeedback]}
          alt={feedBackAltTextMapping[selectedFeedback]}
          width={75}
          height={75}
        />
        <Typography component="p" variant="h2" sx={{ fontWeight:"bold", ml: "20px"}}>
          {t(feedBackTranslationMapping[selectedFeedback])}
        </Typography>
      </Box>

      <Typography sx={{ mt: "20px" , lineHeight: '24px'}}>
        {t('feedback_help')}{' '}<Trans t={t} i18nKey="contact_info" 
components={[<strong key="whatsapp" />]} />
      </Typography>

      <Typography sx={{ mt: "20px", display: {xxs: 'none', md: 'flex', lineHeight: '24px'} }}>
        {t('whatsapp_instructions')}
      </Typography>

      <Grid container justifyContent="center" sx={{ mt: "20px",  alignItems: 'start', justifyContent: 'start' }}>
        <Image className={styles.qrImage} quality="100" src={qrImage} alt="WhatsApp QR Code" width={150} height={150} />
        <ButtonWithConfig sx={{mt: "20px"}} variant='inverted' buttonProps={{buttonLink: 'https://wa.me/4940776655', newWindow: true, buttonText: t('write_to_whatsapp'), icon: 'chevronRight', iconPosition: 'end'}} />
      </Grid>
    </Box>
  );
};

export default FeedbackThankYou;

