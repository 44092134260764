import {FooterBox} from 'context/navPropsContext';
import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {palette} from 'styles/mui/scss';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from 'components/basic-components';
import MarkdownContent from 'components/basic-components/MarkdownContent';
import {NextLink} from 'components/basic-components/NextLink/Link';

import styles from './Footer.module.scss';

const FooterBoxes = ({footerBoxes}: {footerBoxes: FooterBox[]}) => {
  return (
    <>
      {footerBoxes.map((box, index) => {
        return (
          <Box
            key={box.id}
            className={styles.card}
            sx={{
              display: index === 2 ? {xxs: 'none', lg: 'block'} : null,
            }}
          >
            <Typography
              sx={{padding: '20px 20px 7px 20px', display: 'block'}}
              variant="footerCards"
            >
              {box.title}
            </Typography>
            <Box className={styles.cardContentWrapper}>
              {box?.link?.length > 0
                ? box.link.map(link => {
                    if (link?.href && link?.title) {
                      return (
                        <Typography className={styles.footerLinkStyles} key={link.id}>
                          <NextLink target={link?.newWindow ? '_blank' : '_self'} href={link?.href}>
                            {link?.title}
                          </NextLink>
                        </Typography>
                      );
                    } else return null;
                  })
                : null}
              {box?.text ? (
                <MarkdownContent
                  sx={{marginTop: box?.link?.length > 0 ? '10px' : 0}}
                  className={styles.text}
                  content={box?.text}
                />
              ) : null}
            </Box>
          </Box>
        );
      })}
      <Box sx={{width: '100%', display: {xxs: 'block', lg: 'none'}}}>
        <Accordion sx={{backgroundColor: palette.colorGrey03}} variant="base">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{width: '24px', height: '24px'}} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="footerCards">
              {footerBoxes[footerBoxes.length - 1]?.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className={
              footerBoxes[footerBoxes.length - 1]?.link?.length > 0 ? styles.accordionDetails : null
            }
          >
            {footerBoxes[footerBoxes.length - 1]?.link?.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  columnGap: '2%',
                  flexWrap: 'wrap',
                }}
              >
                {footerBoxes[footerBoxes.length - 1]?.link?.map((link, index) => {
                  return (
                    <Box
                      key={link.id}
                      sx={{
                        width: '32%',
                        paddingLeft: index % 3 === 0 ? '15px' : null,
                        paddingBottom: '5px',
                      }}
                    >
                      {link?.title && link?.href ? (
                        <Typography className={styles.footerLinkStyles}>
                          <NextLink href={link?.href}>{link?.title}</NextLink>
                        </Typography>
                      ) : null}
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Typography>{footerBoxes[footerBoxes.length - 1]?.text}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default FooterBoxes;
